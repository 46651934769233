<template>
    <div>
        <Loader v-if="isEnrollmentLoading" />
        <st-page :title="title" layout="section">
            <template #additional-title v-if="isStaffEnrolled">
                <div class="label label-lg label-inline label-primary ml-4">
                    {{ $t("PROFILE.ENROLLED_BADGE_LABEL") }}
                </div>
            </template>
            <template #toolbar>
                <st-button
                    v-if="needStaffEnrollment"
                    variant="secondary"
                    :callback="onVideoEnroll"
                    :disabled="isEnrollmentLoading"
                    :spinner="loading['auth/updateProfile']"
                    customClass="px-5 mr-3"
                >
                    {{ $t("PROFILE.VIDEO_ENROLL_BUTTON") }}
                </st-button>

                <st-button
                    variant="primary"
                    :callback="onSubmit"
                    :spinner="loading['auth/updateProfile']"
                    customClass="px-5 mr-3"
                >
                    {{ $t("PROFILE.UPDATE_BUTTON") }}
                </st-button>
            </template>

            <staff-profile-form
                v-if="isUserStaff"
                ref="userForm"
            ></staff-profile-form>
            <legal-form v-if="isUserLegal" ref="userForm"></legal-form>
            <foreigner-form
                v-if="isUserForeigner"
                ref="userForm"
            ></foreigner-form>
            <citizen-form v-if="isUserCitizen" ref="userForm"></citizen-form>
            <staff-enrollment-form
                v-if="needStaffEnrollment"
                :videoEnrollmentToken="videoEnrollmentToken"
                ref="staffEnrollmentForm"
            />
        </st-page>
    </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

import CitizenForm from "@/modules/profile/components/CitizenForm.vue";
import LegalForm from "@/modules/profile/components/LegalForm.vue";
import ForeignerForm from "@/modules/profile/components/ForeignerForm.vue";
import StaffProfileForm from "@/modules/profile/components/StaffProfileForm.vue";
import StaffEnrollmentForm from "@/modules/profile/components/StaffEnrollmentForm.vue";
import Message from "@/shared/message/message";
import { ENROLL_STAFF } from "@/modules/auth/auth-store";

export default {
    name: "ProfileOverview",
    components: {
        ForeignerForm,
        CitizenForm,
        LegalForm,
        StaffProfileForm,
        StaffEnrollmentForm,
    },
    data() {
        return {
            enrollmentId: this.generateGuid(),
            isEnrollmentLoading: false,
        };
    },
    computed: {
        ...mapGetters({
            currentUser: "auth/currentUser",
            videoEnrollmentToken: "auth/videoEnrollmentToken",
            loading: "shared/loading",
        }),
        isUserCitizen() {
            return this.currentUser.user_type_name === "citizen";
        },
        isUserForeigner() {
            return this.currentUser.user_type_name === "foreigner";
        },
        isUserLegal() {
            return this.currentUser.user_type_name === "legal_entity";
        },
        isUserStaff() {
            return (
                this.currentUser.user_type_name === "admin" ||
                this.currentUser.user_type_name === "super_admin" ||
                this.currentUser.user_type_name === "staff"
            );
        },
        isStaffEnrolled() {
            return this.isUserStaff && this.currentUser.enrolled;
        },
        needStaffEnrollment() {
            if (window.VUE_APP_ENV === 'production') {
                return false;
            }
            return this.isUserStaff && !this.currentUser.enrolled;
        },
        title() {
            if (this.isUserCitizen || this.isUserForeigner)
                return this.$t("PROFILE.HEADER_CITIZEN");
            if (this.isUserLegal) return this.$t("PROFILE.HEADER_LEGAL");
            return this.$t("PROFILE.HEADER");
        },
    },
    methods: {
        ...mapActions({
            updateProfile: "auth/updateProfile",
            updateAccountDetails: "auth/updateAccountDetails",
            enrollStaff: `auth/${ENROLL_STAFF}`,
        }),
        async onSubmit() {
            const form = this.$refs.userForm;
            if (this.isUserCitizen || this.isUserLegal) {
                const validateDate = await form.fv.validateField(
                    "id_card_expiration_date"
                );
                if (validateDate === "Invalid") {
                    Message.error("PROFILE.MESSAGES.ID_CARD_EXPIRED");
                }
            }
            const validateForm = await form.fv.validate();
            if (validateForm === "Valid") {
                let payload = {
                    ...form.model,
                };

                if (this.isUserCitizen || this.isUserLegal) {
                    payload = {
                        ...payload,
                        locality:
                            form.fields.locality_id.options.find(
                                (el) => el.value === form.model.locality_id
                            )?.text || "",
                        county:
                            form.fields.county_id.options.find(
                                (el) => el.value === form.model.county_id
                            )?.text || "",
                        village:
                            form.fields?.village_id.options.find(
                                (el) => el.value === form.model.village_id
                            )?.text || "",
                    };
                }

                this.onUpdateProfile({ payload, fv: form.fv });
            }
        },
        onUpdateProfile({ payload, fv }) {
            this.updateProfile(payload).then(() => {
                Message.success("SET_PROFILE_DATA.UPDATED_MESSAGE");
                fv.resetForm();
            });
        },
        onVideoEnroll() {
            if (this.isStaffEnrolled) {
                return;
            }

            const actualRequest = {
                external_id: this.enrollmentId,
                only_test: true,
                user_id: this.currentUser.user_id,

                // this values are required for proper enrollment with test = true
                first_name: "Firstname",
                last_name: "Lastname",

                email: this.currentUser.email,
                phone_number: this.currentUser.phone_number,
            };

            this.isEnrollmentLoading = true;
            this.enrollStaff(actualRequest)
                .then((data) => {
                    this.$refs.staffEnrollmentForm.submit();
                })
                .catch(() => {
                    this.isEnrollmentLoading = false;
                });
        },
        generateGuid() {
            return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'
                .replace(/[xy]/g, function (c) {
                    const r = Math.random() * 16 | 0,
                        v = c == 'x' ? r : (r & 0x3 | 0x8);
                    return v.toString(16);
                });
        },
    },
};
</script>
